<template>
  <Animated name="pulse" appear duration="1200">
    <div class="">
      <sequential-entrance fromTop>
        <SellerHeader to="/seller/" headerText="Precios" :superAdmin="true" />
      </sequential-entrance>

      <h3 class="text-center p-2 mb-0 pt-5 font-weight-bold">
        {{ event.name }}
      </h3>

      <v-card
        class="my-8 mx-md-1 mx-auto"
        v-if="business_pricing"
        style="width:100%!important"
      >
        <v-row justify="center" class="text-center">
          <v-col cols="12">
            <v-card-title class="justify-content-center font-weight-bold">
              Plantilla de Pricing por Business
            </v-card-title>
          </v-col>

          <v-col cols="6" class="border-right">
            <v-card-text>
              Business: <strong>{{ business_pricing.business.name }}</strong>
            </v-card-text>
            <v-card-text>
              Precio Fijo:
              <strong>{{
                business_pricing.fixed_value
                  ? '$' + formatPrice(business_pricing.fixed_value)
                  : '-'
              }}</strong>
            </v-card-text>
          </v-col>

          <v-col cols="6">
            <v-card-text>
              Tipo: <strong>{{ business_pricing.pricing_type }}</strong>
            </v-card-text>
            <v-card-text>
              Precio Variable:
              <strong>{{
                business_pricing.qr_fixed_value
                  ? '$' + formatPrice(business_pricing.qr_fixed_value)
                  : '-'
              }}</strong>
            </v-card-text>
          </v-col>

          <v-col cols="12">
            <v-card-text>
              Base de asistentes:
              <strong>{{
                business_pricing.assistants_flat
                  ? business_pricing.assistants_flat
                  : '-'
              }}</strong>
            </v-card-text>
          </v-col>

          <v-card-actions class="pb-5" v-if="!event_pricing">
            <v-btn block color="primary" @click="createEventPricing()">
              Replicar en el evento
            </v-btn>
          </v-card-actions>
        </v-row>
      </v-card>

      <div class="text-center mt-5" v-if="!business_pricing">
        La empresa no tiene un modelo de pricing asignado. Se debe agregar uno
        desde el Backoffice.
      </div>

      <div class="text-center mt-5" v-if="!event_pricing">
        El evento no tiene modelo de precios asignado. Replica la plantilla de
        la empresa para comenzar y poder editarlo.
      </div>

      <v-card class="my-8 mx-1" v-if="event_pricing">
        <v-row justify="center" class="text-center">
          <v-col cols="12">
            <v-card-title class="justify-content-center font-weight-bold">
              Plantilla de Pricing del Evento
            </v-card-title>
          </v-col>

          <v-col cols="6" class="border-right">
            <div class="px-5">
              <v-select
                item-text="name"
                item-value="value"
                :items="types"
                v-model="event_pricing.pricing_type"
                label="Tipo de pricing"
                rounded
                color="#00B8D9"
                background-color="#F4F5F7"
                class="app-input__select"
              ></v-select>
            </div>

            <div class="px-5" v-if="event_pricing.pricing_type == 'mixed'">
              <v-text-field
                type="number"
                required
                label="Base de asistentes"
                v-model="event_pricing.assistants_flat"
                color="#00B8D9"
                flat
                background-color="#F4F5F7"
                class="app-input"
              ></v-text-field>
            </div>
          </v-col>

          <v-col cols="6">
            <div
              class="px-5"
              v-if="
                event_pricing.pricing_type == 'mixed' ||
                  event_pricing.pricing_type == 'fixed'
              "
            >
              <v-text-field
                type="number"
                required
                label="Valor Fijo"
                v-model="event_pricing.fixed_value"
                color="#00B8D9"
                flat
                background-color="#F4F5F7"
                class="app-input"
              ></v-text-field>
            </div>

            <div
              class="px-5"
              v-if="
                event_pricing.pricing_type == 'mixed' ||
                  event_pricing.pricing_type == 'variable'
              "
            >
              <v-text-field
                type="number"
                required
                label="Valor Variable"
                v-model="event_pricing.qr_fixed_value"
                color="#00B8D9"
                flat
                background-color="#F4F5F7"
                class="app-input"
              ></v-text-field>
            </div>
          </v-col>

          <v-card-actions class="pb-5">
            <v-btn block color="primary" @click="updateEventPricing()">
              Actualizar
            </v-btn>
          </v-card-actions>
        </v-row>
      </v-card>
    </div>
  </Animated>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'
import {
  getPricingOfEvent,
  createEventPricing,
  updateEventPricing,
} from '@/services/seller.service'
import SellerHeader from '@/components/Seller/SellerHeader.vue'
import { getMyData } from '@/services/user.service'

export default {
  data() {
    return {
      user: null,
      business_pricing: null,
      event_pricing: null,
      types: [
        { name: 'Fijo', value: 'fixed' },
        { name: 'Variable', value: 'variable' },
        { name: 'Mixto', value: 'mixed' },
      ],
      event: null,
    }
  },
  computed: {},
  methods: {
    async getMyData() {
      try {
        const { data } = await getMyData()
        this.user = data

        if (this.user.role_id !== 7) {
          Vue.$toast.error(`No tienes permiso para acceder a esta página`)
          setTimeout(() => {
            this.$router.push('/seller')
          }, 2000)
        }
      } catch (e) {
        console.error(e)
      }
    },
    async getPricingOfEvent() {
      const response = await getPricingOfEvent(this.$route.params.id)

      this.event = response.data.event
      this.business_pricing = response.data.business_pricing
        ? response.data.business_pricing
        : null
      this.event_pricing = response.data.event_pricing
        ? response.data.event_pricing
        : null
    },
    async createEventPricing() {
      const body = {
        event_id: Number(this.$route.params.id),
        pricing_type: this.business_pricing.pricing_type,
        fixed_value: this.business_pricing.fixed_value,
        qr_fixed_value: this.business_pricing.qr_fixed_value,
        assistants_flat: this.business_pricing.assistants_flat,
      }

      const response = await createEventPricing(body)

      if (response.status === 200) {
        Vue.$toast.success(`El pricing del evento ha sido creado correctamente`)
        this.getPricingOfEvent()
      } else {
        Vue.$toast.error(`Ha ocurrido un problema replicando el modelo`)
      }
    },
    async updateEventPricing() {
      const body = {
        pricing_type: this.event_pricing.pricing_type,
        fixed_value:
          this.event_pricing.pricing_type == 'fixed' ||
          this.event_pricing.pricing_type == 'mixed'
            ? this.event_pricing.fixed_value
            : null,
        qr_fixed_value:
          this.event_pricing.pricing_type == 'variable' ||
          this.event_pricing.pricing_type == 'mixed'
            ? this.event_pricing.qr_fixed_value
            : null,
        assistants_flat:
          this.event_pricing.pricing_type == 'mixed'
            ? this.event_pricing.assistants_flat
            : null,
      }

      const response = await updateEventPricing(body, this.event_pricing.id)

      if (response.status === 200) {
        Vue.$toast.success(
          `El pricing del evento ha sido actualizado correctamente`
        )
        this.getPricingOfEvent()
      } else {
        Vue.$toast.error(`Ha ocurrido un problema actualizando el modelo`)
      }
    },
    formatPrice(value) {
      const val = (value / 1).toFixed().replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
  },
  mounted() {
    this.getMyData()
    this.getPricingOfEvent()
  },
  components: {
    SellerHeader,
  },
}
</script>

<style lang="scss" scope></style>
